/* eslint-disable no-console */
import { FC, useMemo } from 'react';
import { Col, IconEmphasized, Row } from 'components';
import { useParams, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Assessment } from '../../common-types/assessments';
import { selectInstitute } from '../../store/modules/auth';
import { Title } from '../AssessmentForms/components/assessmentForm/formPageTitle/Title';
import { TitleMenu } from '../AssessmentForms/components/assessmentForm/formPageTitle/TitleMenu';
import { deleteAssessment } from 'store/modules/assessments';

export const AssessmentPageTitle: FC = () => {
  const url = useRouteMatch();
  const dispatch = useDispatch();
  const { assessmentId } = useParams<{ assessmentId: string }>();

  const assessment = useSelector<Record<string, any>, Assessment>((state) => {
    return state.entities.assessments[assessmentId];
  });

  const institute = useSelector(selectInstitute);

  const title = useMemo(() => {
    return assessment && assessment.title;
  }, [assessment]);

  const instituteName = useMemo(() => {
    return institute && institute.name;
  }, [institute]);

  const traineeName = useMemo(() => {
    if (assessment?.trainee)
      return `${assessment.trainee?.givenName} ${assessment.trainee?.surname}`;
    else return '';
  }, [assessment?.trainee]);

  const icon = useMemo(() => {
    if (url.path.includes('create') || url.path.includes('edit')) {
      return <IconEmphasized icon="edit" type="info" />;
    } else if (url.path.includes('execute')) {
      return <IconEmphasized icon="check-circle" type="info" />;
    }
    return <IconEmphasized icon="library" type="info" />;
  }, [url.path]);

  const onAssessmentDeleteAction = () => {
    dispatch(deleteAssessment(assessmentId));
  };

  return (
    <Row alignItems="center" justifyContent="space-between" nowrap>
      <Col>
        <Row alignItems="center" nowrap>
          <Col auto>{icon}</Col>
          <Col>
            <Title
              pageTitle={title}
              institute={instituteName}
              traineeName={traineeName}
            />
          </Col>
          <Col auto>
            <TitleMenu onDeleteAction={onAssessmentDeleteAction} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
