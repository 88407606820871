import { OverflowText, Text } from 'components';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { RootState } from 'v2/app/store';
import { QualificationFormResponse } from 'v2/model/qualificationForm';

export const QualificationTitle: FC = () => {
  const { t } = useTranslation();

  const qualificationForms = useSelector(
    (state: RootState) => state.qualificationForm.qualificationForm
  );

  const { qualificationFormId } =
    useParams<{ qualificationFormId: string; qualificationId: string }>();

  const pageTitle = useMemo(() => {
    const qualificationForm = (
      qualificationForms as unknown as QualificationFormResponse[]
    )?.find((q) => q.id === qualificationFormId);

    return qualificationForm?.title || t('qualification.qualificationTitle');
  }, [qualificationFormId, qualificationForms, t]);

  return (
    <Text variant="heading-xl" className="mb-0">
      <OverflowText component="span" maxLines={1}>
        {pageTitle}
      </OverflowText>
    </Text>
  );
};
