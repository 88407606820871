import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import Bugsnag from '@bugsnag/js';
import { selectInstitutes } from 'store/modules/entities/selectors/selectors';
import { ConfirmModal, DropList, DropListItem } from 'components';
import { ASSESSMENT_FORMS } from 'scenes/routes.enum';
import { useSelector } from 'react-redux';
import { Assessment } from 'common-types/assessments';
import { createPdf } from 'api/v1';
import { format } from 'date-fns';
import useDownloadPdf from 'v2/hooks/useDownloadPDF';

interface TitleMenuProps {
  onDeleteAction: () => void;
}

export const TitleMenu: FC<TitleMenuProps> = ({ onDeleteAction }) => {
  const downloadPdf = useDownloadPdf();
  const { t } = useTranslation();
  const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);
  const institutes = useSelector(selectInstitutes);

  const history = useHistory();

  const objRoute = useRouteMatch();
  const route = objRoute.path.split('/')[1];
  const { params } = objRoute;
  const assessmentId = params['assessmentId'];

  const assessment = useSelector<Record<string, any>, Assessment>((state) => {
    return state.entities.assessments[assessmentId];
  });

  const onDelete = useCallback(async () => {
    try {
      await onDeleteAction();
      setDeleteModalOpened(false);
      history.replace(ASSESSMENT_FORMS);
    } catch (error) {
      Bugsnag.notify(error);
    }
  }, []);

  const handleDownload = async () => {
    const institute = institutes[assessment.trainer.instituteId];
    const formattedDate = format(new Date(assessment.createdAt), 'dd.MM.yyyy');
    const pdfName = `${assessment.trainee.surname}_${assessment.trainee.givenName}_${assessment.title}_${formattedDate}.pdf`;

    const pdfContent: any = await createPdf(assessment, institute);

    downloadPdf(pdfContent, pdfName);
  };

  return (
    <>
      <ConfirmModal
        title={t('assessmentForms.deleteForm')}
        confirmButtonLabel={t('Delete')}
        declineButtonLabel={t('cancel')}
        content={t('assessmentForms.deleteAssessmentModalContent')}
        onDecline={() => setDeleteModalOpened(false)}
        onConfirm={onDelete}
        confirmButtonVariant="secondary-danger"
        isOpen={deleteModalOpened}
      />
      <DropList label={t('Options')} size="md">
        <DropListItem
          icon="delete"
          onClick={() => setDeleteModalOpened(true)}
          className="text-error"
        >
          {t('assessmentForms.deleteForm')}
        </DropListItem>
        {route === 'assessments' && assessment?.status === 'verified' && (
          <DropListItem icon="download" onClick={handleDownload}>
            {t('assessmentForms.downloadAssessment')}
          </DropListItem>
        )}
      </DropList>
    </>
  );
};
