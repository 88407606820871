/* eslint-disable no-console */
import Bugsnag from '@bugsnag/js';
import { ConfirmModal, DropList, DropListItem } from 'components';
import { format } from 'date-fns';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'v2/app/store';
import useDownloadPdf from 'v2/hooks/useDownloadPDF';
import { User } from 'v2/model/user';
import { fetchUserById } from 'v2/reducers/userSlice';
import { qualificationService } from 'v2/services/services';
import { QUALIFICATION_TABLE } from 'scenes/routes.enum';
import { useHistory } from 'react-router-dom';

interface TitleMenuProps {
  onDeleteAction: () => Promise<void>;
  user: User;
}

export const TitleMenu: FC<TitleMenuProps> = ({ user, onDeleteAction }) => {
  const downloadPdf = useDownloadPdf();
  const { t } = useTranslation();
  const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);

  const history = useHistory();

  const qualifications = useSelector(
    (state: RootState) => state.qualification.qualifications
  );

  const dispatch = useDispatch<AppDispatch>();

  const onDelete = useCallback(async () => {
    try {
      await onDeleteAction();
      setDeleteModalOpened(false);
      history.replace(QUALIFICATION_TABLE);
    } catch (error) {
      Bugsnag.notify(error);
    }
  }, []);

  const shouldShowDeleteButton = useMemo(() => {
    if (qualifications && qualifications[0] && user) {
      const trainerId = qualifications[0].trainerId;
      const userId = user.id;
      return +trainerId === +userId;
    }
    return false;
  }, [qualifications, user]);

  const isQualificationVerified = useMemo(
    () => qualifications?.[0]?.status === 'verified',
    [qualifications]
  );

  const handleDownload = async () => {
    if (!qualifications) return;

    const qualification = qualifications[0];
    if (qualification.status !== 'verified') return;

    const traineeId = qualification.traineeId;

    const trainee: User = (await dispatch(fetchUserById(traineeId)))
      .payload as User;

    let pdfName = `${trainee.givenName}_${trainee.surname}_${qualification.title}`;
    if (qualification.date) {
      const formattedDate = format(new Date(qualification.date), 'dd.MM.yyyy');
      pdfName += `_${formattedDate}`;
    }

    const pdfContent: any = await qualificationService.createPdf(
      qualification.id
    );

    downloadPdf(pdfContent, pdfName);
  };

  return (
    <>
      <ConfirmModal
        title={t('qualification.deleteForm')}
        confirmButtonLabel={t('Delete')}
        declineButtonLabel={t('cancel')}
        content={t('qualification.deleteQualificationModalContent')}
        onDecline={() => setDeleteModalOpened(false)}
        onConfirm={onDelete}
        confirmButtonVariant="secondary-danger"
        isOpen={deleteModalOpened}
      />

      {(isQualificationVerified || shouldShowDeleteButton) && (
        <DropList label={t('Options')} size="md">
          {isQualificationVerified && (
            <DropListItem icon="download" onClick={handleDownload}>
              {t('qualification.downloadPdf')}
            </DropListItem>
          )}
          {shouldShowDeleteButton && (
            <DropListItem
              icon="delete"
              onClick={() => setDeleteModalOpened(true)}
              className="text-error"
            >
              {t('Delete')}
            </DropListItem>
          )}
        </DropList>
      )}
    </>
  );
};
