/* eslint-disable no-console */
import { Col, Row } from 'components';
import { FC } from 'react';
import { TitleMenu } from 'scenes/QualificationForm/TitleMenu';
import { Provider, useDispatch } from 'react-redux';
import { AppDispatch, store } from 'v2/app/store';
import { QualificationTitle } from './QualificationTitle';
import { useParams } from 'react-router';
import { deleteQualification } from 'v2/reducers/qualificationSlice';
import { User } from 'v2/model/user';

interface QualificationPageTitleProps {
  user: User;
}

export const QualificationPageTitle: FC<QualificationPageTitleProps> = ({
  user,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { qualificationId } = useParams<{ qualificationId: string }>();

  const deleteQualificationById = async () => {
    await dispatch(deleteQualification(qualificationId));
  };

  return (
    <Provider store={store}>
      <Row alignItems="center" justifyContent="space-between" nowrap>
        <Col>
          <Row alignItems="center" nowrap>
            <Col>
              <QualificationTitle />
            </Col>
            <Col auto>
              {qualificationId && (
                <TitleMenu
                  user={user}
                  onDeleteAction={deleteQualificationById}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Provider>
  );
};
